import { loadScript } from "./core/util/assets";
import { FullStory, init } from '@fullstory/browser';

let gainsightInitialized = false;

export const startAnalytics = function (params) {
    initFullstory(params);

    // Setup Gainsight (EN-38900)
    if (params.gainsightParams) {
        try {
             (function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
                 (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
               var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
               var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
             })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js", params.gainsightParams.gainsightTagKey);

            if (!window.aptrinsic) {
                return;
            }

            let firstName = null;
            let lastName = null;
            // these are sometimes email addresses, but try our best to pull out first / last names
            const splitName = params.gainsightParams.name?.split(" ");
            if (splitName) {
                if (splitName.length > 0)
                    firstName = splitName[0];
                if (splitName.length > 1)
                    lastName = splitName[splitName.length - 1];
            }

            //passing user and account objects:
            window.aptrinsic("identify",
                {
                    //User Fields
                    "id": params.gainsightParams.email, // Required for logged in app users
                    "email": params.gainsightParams.email,
                    "firstName": firstName,
                    "lastName": lastName,
                    "VIPReadOnlyUser": params.gainsightParams.isReadOnly,
                    "VIPReadWriteUser": !params.gainsightParams.isReadOnly,
                    // apparently gainsight doesn't support arrays? so send comma separated string
                    "VIPGroupMemberships": params.gainsightParams.groupMemberships.join(","),
                    "VIPExternalAccessUser": params.gainsightParams.isExternalAccessOnly
                },
                {
                    //Account Fields
                    "id": params.gainsightParams.companyCode, //Required
                    "name": params.gainsightParams.companyName
                });

            gainsightInitialized = true;
        }
        catch (error) {
            console.error(error);
        }
    }

}

function initFullstory(params) {
    const { fullstoryParams } = params;
    if (!fullstoryParams) {
        return;
    }

    const { orgId, host, script, identity } = fullstoryParams;

    try {
        init({
            orgId,
            host,
            script,
            appHost: "app.eu1.fullstory.com"
        })
        FullStory('setIdentity', identity);
    } catch (error) {
        console.error(error);
    }
}

export const trackingEnabled = () => gainsightInitialized;

export const trackEvent = (name, data) => {
    if (!gainsightInitialized) {
        return;
    }

    try {
        window.aptrinsic("track", name, {
            Url: window.location.href,
            ...data
        });
    } catch (error) {
        console.error(error);
    }
}